import wp from '../../authentication';

export const filterPost = async (page, id, startDate, endDate, search, status, perPage) => {
    var wpauth =  new wp();

    const validStartDate = (startDate === null) ? false : true;
    const validEndDate = (endDate === null) ? false : true;
    let query;
    if (validStartDate && validEndDate) {
        query =  wpauth.posts().categories(id).before(endDate).after(startDate).search(search).status(status).page(page).perPage(perPage).order('desc').orderby('modified');
    } else if (validStartDate) {
        query = wpauth.posts().categories(id).after(startDate).search(search).status(status).page(page).perPage(perPage).order('desc').orderby('modified');
    } else if (validEndDate) {
        query = wpauth.posts().categories(id).before(endDate).search(search).status(status).page(page).perPage(perPage).order('desc').orderby('modified');
    } else {
        query = wpauth.posts().categories(id).search(search).status(status).page(page).perPage(perPage).order('desc').orderby('modified');
    }
    return await query
        .then(function (response) {
            var data = response;
            return data;
        }
        );

};

export default filterPost;