import { useState, useEffect, Fragment, useContext } from "react";
import { useRouter } from "next/router";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import Fab from "@material-ui/core/Fab";
import Fade from 'react-reveal/Fade';
import { Tooltip } from "@material-ui/core";
import { getPages } from '../../src/Database/Pages/GetPages';
import { filterPage } from '../../src/Database/Pages/FilterPage';
import { getPosts } from '../../src/Database/Posts/GetPosts';
import { filterPost } from '../../src/Database/Posts/FilterPost';
import { getMostUsedCategories } from '../../src/Database/Categories/GetMostUsedCategories';
import { getMedias } from '../../src/Database/Medias/GetMedias';
import { filterMedia } from '../../src/Database/Medias/FilterMedia';
import * as Sentry from '@sentry/node';
import { ErrorHandling } from '../../src/ErrorContext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  typography: {
    display: 'flex',
    flex: 1

  },
  enabledButton: {
    color: '#FFFFFF',
    backgroundColor: theme.palette.gray.main,
    borderRadius: 0,
    "&:hover": {
      color: theme.palette.gray.main,
    }
  },
  disabledButton: {
    borderRadius: 0,
  },
  allAddButton: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  groupButtons: {
    justifyContent: 'flex-start',
    display: 'flex',
    verticalAlign: 'top',
    width: '100%',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    alignContent: 'stretch'
  },
  table: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  row: {
    width: '100%',
    height: 'calc((100vh - 180px)/100)',
  },
  cell: {
    backgroundColor: 'transparent'
  },
  fab: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden',
    "&:hover": {
      backgroundColor: 'transparent'
    }
  },
  icon: {
    color: theme.palette.gray.light
  },
  cellWithHeight: {
    padding: theme.spacing(1),
    margin: 16,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    textOverflow: 'ellipsis',
    height: 'calc((100vh - 180px)/80)',
    width: 'calc((100vw - 240px)/4)',
    overflow: 'hidden',
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: theme.spacing(4)
  },
  tableCell: {
    width: 'calc((100vw - 240px)/4)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    justifyContent: 'center'
  },
  fixedHeight: {
    height: '100%',
  },
}));


const PageHome = () => {
  const classes = useStyles();
  const [loadingPages, setLoadingPages] = useState(true);
  const [loadingPosts, setLoadingPosts] = useState(true);
  const [loadingMedias, setLoadingMedias] = useState(true);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [pages, setPages] = useState([]);
  const [allPages, setAllPages] = useState([]);
  const [weekPages, setWeekPages] = useState([]);
  const [posts, setPosts] = useState([]);
  const [allPosts, setAllPosts] = useState([]);
  const [weekPosts, setWeekPosts] = useState([]);
  const [medias, setMedias] = useState([]);
  const [allMedias, setAllMedias] = useState([]);
  const [weekMedias, setWeekMedias] = useState([]);
  const [categories, setCategories] = useState([]);
  const [pageWeekSelected, setPageWeekSelected] = useState(false);
  const [postWeekSelected, setPostWeekSelected] = useState(false);
  const [mediaWeekSelected, setMediaWeekSelected] = useState(false);
  const { setOpen } = useContext(ErrorHandling);
  const router = useRouter();

  const getPagesData = async () => {
    try {
      let finaldata;
      finaldata = await getPages(0, 8);
      setPages(finaldata);
      setAllPages(finaldata);
      setLoadingPages(false);
    }
    catch (err) {
      console.error(err);
      Sentry.captureException(err);
      await setLoadingPages(false);
      setOpen(true);
    }
    return;
  };

  const getWeeklyPagesData = async () => {
    try {
      let finaldata;
      var dateOffset = (24 * 60 * 60 * 1000) * 30;
      var startDate = (new Date()).getTime() - dateOffset;
      finaldata = await filterPage(1, startDate, null, '', ['publish,', 'draft']);
      finaldata = finaldata.slice(0, 15);
      setWeekPages(finaldata);
    }
    catch (err) {
      console.error(err);
      Sentry.captureException(err);
      await setLoadingPages(false);
      setOpen(true);
    }
    return;
  };

  const getPostsData = async () => {
    try {
      let finaldata;
      finaldata = await getPosts(0, 8);
      setPosts(finaldata);
      setAllPosts(finaldata);
      setLoadingPosts(false);
    }
    catch (err) {
      console.error(err);
      Sentry.captureException(err);
      await setLoadingPosts(false);
      setOpen(true);
    }

    return;
  };

  const getWeeklyPostsData = async () => {
    try {
      let finaldata;
      var dateOffset = (24 * 60 * 60 * 1000) * 30;
      var startDate = (new Date()).getTime() - dateOffset;
      finaldata = await filterPost(1, [], startDate, null, '', ['publish,', 'draft']);
      finaldata = finaldata.slice(0, 15);
      setWeekPosts(finaldata);
    }
    catch (err) {
      console.error(err);
      Sentry.captureException(err);
      await setLoadingPosts(false);
      setOpen(true);
    }
    return;
  };

  const getMediasData = async () => {
    try {
      let finaldata;
      finaldata = await getMedias(0, 5);
      setMedias(finaldata);
      setAllMedias(finaldata);
      setLoadingMedias(false);
    }
    catch (err) {
      console.error(err);
      Sentry.captureException(err);
      await setLoadingMedias(false);
      setOpen(true);
    }
    return;
  };

  const getWeeklyMediasData = async () => {
    try {
      let finaldata;
      var dateOffset = (24 * 60 * 60 * 1000) * 30;
      var startDate = (new Date()).getTime() - dateOffset;
      finaldata = await filterMedia(1, startDate, null);
      finaldata = finaldata.slice(0, 15);
      setWeekMedias(finaldata);
    }
    catch (err) {
      console.error(err);
      Sentry.captureException(err);
      await setLoadingMedias(false);
      setOpen(true);
    }

    return;
  };

  const getImportantCategories = async () => {
    try {
      let finaldata;
      finaldata = await getMostUsedCategories(1, 5);
      setCategories(finaldata);
      setLoadingCategories(false);
    }
    catch (err) {
      console.error(err);
      Sentry.captureException(err);
      await setLoadingCategories(false);
      setOpen(true);
    }
    return;
  };

  useEffect(() => {
    getPagesData();
    getWeeklyPagesData();
    getPostsData();
    getWeeklyPostsData();
    getImportantCategories();
    getMediasData();
    getWeeklyMediasData();
  }, []);

  function LUTStatus(status) {
    switch (status) {
      case 'publish':
        return 'Δημοσιευμένο';
      case 'draft':
        return 'Πρόχειρο';
      case 'trash':
        return 'Διαγραμμένο';
      default:
        return;
    }
  }

  function LUTType(type) {
    switch (type) {
      case 'file':
        return 'Αρχείο';
      case 'image':
        return 'Εικόνα';
      default:
        return;
    }
  }

  function handleChipStatusColor(status) {
    switch (status) {
      case 'publish':
        return 'primary';
      case 'draft':
        return 'secondary';
      case 'trash':
        return 'error';
      default:
        return;
    }
  }

  function handleChipTypeColor(type) {
    switch (type) {
      case 'image':
        return 'primary';
      case 'file':
        return 'secondary';
      default:
        return;
    }
  }

  const handlePages = (flag) => {
    if (flag) {
      setPageWeekSelected(false);
      setPages(allPages);
    }
    else {
      setPageWeekSelected(true);
      setPages(weekPages);
    }
  };

  const handlePosts = (flag) => {
    if (flag) {
      setPostWeekSelected(false);
      setPosts(allPosts);
    }
    else {
      setPostWeekSelected(true);
      setPosts(weekPosts);
    }
  };

  const handleMedias = (flag) => {
    if (flag) {
      setMediaWeekSelected(false);
      setMedias(allMedias);
    }
    else {
      setMediaWeekSelected(true);
      setMedias(weekMedias);
    }
  };

  return (
    <div>
      <Container direction="column" id="container" maxWidth="xl" className={classes.container}>
        <Grid container id="grid-container" spacing={3}  >
          <Grid item xs={12} md={6} lg={6}>
            <Paper className={classes.paper}>
              <Grid item xs={12} className={classes.groupButtons}>
                <div style={{ display: 'contents' }}>
                  <Typography colSpan={6} className={classes.typography}>
                    Πρόσφατα 'Αρθρα
              </Typography>
                  <Button
                    id="add-button"
                    type="submit"
                    variant="outlined"
                    className={postWeekSelected ? classes.disabledButton : classes.enabledButton}
                    size="small"
                    onClick={() => handlePosts(true)}
                  >
                    {allPosts.length} ΤΕΛΕΥΤΑΙΑ
              </Button>
                  <Button
                    id="add-button"
                    type="submit"
                    variant="outlined"
                    className={postWeekSelected ? classes.enabledButton : classes.disabledButton}
                    size="small"
                    onClick={() => handlePosts(false)}
                  >
                    ΤΕΛΕΥΤΑΙΟΣ ΜΗΝΑΣ
              </Button>
                </div>
              </Grid>
              {loadingPosts ? (
                <div className={classes.progress} >
                  <CircularProgress />
                </div>
              ) : (
                  posts.length > 0 ? (
                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                      <TableHead id="header">
                        <TableRow>

                          <TableCell id="checkbox" padding="none" className={classes.cell}>
                            Τίτλος
                    </TableCell>

                          <TableCell id="checkbox" padding="none" className={classes.cell}>
                            Κατάσταση
                     </TableCell>

                          <TableCell id="checkbox" padding="none" className={classes.cell}>
                            Ενέργεια
                    </TableCell>

                        </TableRow>
                      </TableHead>
                      <TableBody id="table-body" >
                        {posts.map((post) => {
                          return (
                            <Fragment key={post.id}>
                              <TableRow
                                className={classes.row}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={classes.cellWithHeight}
                                >
                                  <Fade>

                                    <div
                                      className={classes.tableCell}
                                      style={{ verticalAlign: "center", padding: 0 }}>
                                      {post.title.rendered}
                                    </div>
                                  </Fade>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{ verticalAlign: "center", padding: 0 }}
                                >
                                  <Fade>
                                    <div>
                                      <Chip label={LUTStatus(post.status)} size="small" color={handleChipStatusColor(post.status)} />
                                    </div>
                                  </Fade>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="center"
                                  style={{ verticalAlign: "center", width: '5%', padding: 0, margin: 0 }}
                                >
                                  <Fab
                                    style={{ verticalAlign: "center" }}
                                    aria-label="edit"
                                    className={classes.fab}
                                    type="submit"
                                    size="small"
                                    onClick={() => {
                                      router.push(
                                        `/posts/edit/${post.id}`
                                      );
                                    }}
                                  >
                                    <Fade>
                                      <EditSharpIcon className={classes.icon} />
                                    </Fade>
                                  </Fab>
                                </TableCell>
                              </TableRow>
                            </Fragment>
                          );
                        }
                        )
                        }
                      </TableBody>
                    </Table>
                  ) : (
                      <div className={classes.progress} >Δε βρέθηκαν αποτελέσματα.</div>
                    )
                )}

              <Grid item xs={12} className={classes.groupButtons} style={{ justifyContent: 'flex-end' }}>
                <Button
                  id="add-button"
                  type="submit"
                  variant="outlined"
                  className={classes.allAddButton}
                  onClick={() => {
                    router.push("/posts");
                  }}
                >
                  ΕΜΦΑΝΙΣΗ ΟΛΩΝ
              </Button>
                <Button
                  id="add-button"
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.allAddButton}
                  onClick={() => {
                    router.push("/posts/add");
                  }}
                >
                  ΔΗΜΙΟΥΡΓΙΑ
              </Button>
              </Grid>
            </Paper>

          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Paper className={classes.paper}>
              <Grid item xs={12} className={classes.groupButtons}>
                <Typography colSpan={6} className={classes.typography}>
                  Πρόσφατες Σελίδες
              </Typography>
                <Button
                  id="add-button"
                  type="submit"
                  variant="outlined"
                  className={pageWeekSelected ? classes.disabledButton : classes.enabledButton}
                  size="small"
                  onClick={() => handlePages(true)}
                >
                  {allPages.length} ΤΕΛΕΥΤΑΙΕΣ
              </Button>
                <Button
                  id="add-button"
                  type="submit"
                  variant="outlined"
                  className={pageWeekSelected ? classes.enabledButton : classes.disabledButton}
                  size="small"
                  onClick={() => handlePages(false)}
                >
                  ΤΕΛΕΥΤΑΙΟΣ ΜΗΝΑΣ
              </Button>
              </Grid>
              {loadingPages ? (
                <div className={classes.progress} >
                  <CircularProgress />
                </div>
              ) : (
                  pages.length > 0 ? (
                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                      <TableHead id="header">
                        <TableRow>
                          <TableCell id="checkbox" padding="none" className={classes.cell}>
                            Τίτλος
                    </TableCell>
                          <TableCell id="checkbox" padding="none" className={classes.cell}>
                            Κατάσταση
                     </TableCell>
                          <TableCell id="checkbox" padding="none" className={classes.cell}>
                            Ενέργεια
                    </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody id="table-body" >
                        {pages.map((page) => {
                          return (
                            <Fragment key={page.id}>
                              <TableRow
                                className={classes.row}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={classes.cellWithHeight}
                                >
                                  <Fade>
                                    <div
                                      className={classes.tableCell}
                                      style={{ verticalAlign: "center", padding: 0 }}>
                                      {page.title.rendered}
                                    </div>
                                  </Fade>

                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{ verticalAlign: "center", padding: 0 }}
                                >
                                  <Fade>

                                    <div>
                                      <Chip label={LUTStatus(page.status)} size="small" color={handleChipStatusColor(page.status)} />
                                    </div>
                                  </Fade>

                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="center"
                                  style={{ verticalAlign: "center", width: '5%', padding: 0, margin: 0 }}
                                >
                                  <Fab
                                    style={{ verticalAlign: "center" }}
                                    aria-label="edit"
                                    className={classes.fab}
                                    type="submit"
                                    size="small"
                                    onClick={() => {
                                      router.push(
                                        `/pages/edit/${page.id}`
                                      );
                                    }}
                                  >
                                    <Fade>
                                      <EditSharpIcon className={classes.icon} />
                                    </Fade>
                                  </Fab>
                                </TableCell>
                              </TableRow>
                            </Fragment>
                          );
                        })}
                      </TableBody>
                    </Table>
                  ) : (
                      <div className={classes.progress} >Δε βρέθηκαν αποτελέσματα.</div>
                    )
                )}

              <Grid item xs={12} className={classes.groupButtons} style={{ justifyContent: 'flex-end' }}>
                <Button
                  id="add-button"
                  type="submit"
                  variant="outlined"
                  className={classes.allAddButton}
                  onClick={() => {
                    router.push("/pages");
                  }}
                >
                  ΕΜΦΑΝΙΣΗ ΟΛΩΝ
              </Button>
              <Tooltip title="Επικοινωνήστε με τον διαχειριστή για την δημιουργία σελίδας" placement="top-start" arrow >
                <span>
                    <Button
                      id="add-button"
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled
                      className={classes.allAddButton}
                      onClick={() => {
                        router.push("/pages/add");
                      }}
                    >
                      ΔΗΜΙΟΥΡΓΙΑ
                  </Button>
                </span>
              </Tooltip>
              </Grid>
            </Paper>

          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Paper className={classes.paper}>
              <Grid item xs={12} className={classes.groupButtons}>
                <div style={{ display: 'contents' }}>
                  <Typography colSpan={6} className={classes.typography}>
                    Πρόσφατα Αρχεία
              </Typography>
                  <Button
                    id="add-button"
                    type="submit"
                    variant="outlined"
                    className={mediaWeekSelected ? classes.disabledButton : classes.enabledButton}
                    size="small"
                    onClick={() => handleMedias(true)}
                  >
                    {allMedias.length} ΤΕΛΕΥΤΑΙΑ
              </Button>
                  <Button
                    id="add-button"
                    type="submit"
                    variant="outlined"
                    className={mediaWeekSelected ? classes.enabledButton : classes.disabledButton}
                    size="small"
                    onClick={() => handleMedias(false)}
                  >
                    ΤΕΛΕΥΤΑΙΟΣ ΜΗΝΑΣ
              </Button>
                </div>
              </Grid>
              {loadingMedias ? (
                <div className={classes.progress} >
                  <CircularProgress />
                </div>
              ) : (

                  medias.length > 0 ? (
                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                      <TableHead id="header">
                        <TableRow>

                          <TableCell id="checkbox" padding="none" className={classes.cell}>
                            Τίτλος
                    </TableCell>

                          <TableCell id="checkbox" padding="none" className={classes.cell}>
                            Τύπος
                     </TableCell>

                          <TableCell id="checkbox" padding="none" className={classes.cell}>
                            Ενέργεια
                    </TableCell>

                        </TableRow>
                      </TableHead>
                      <TableBody id="table-body" >
                        {medias.map((media) => {
                          return (
                            <Fragment key={media.id}>
                              <TableRow
                                className={classes.row}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={classes.cellWithHeight}
                                >
                                  <Fade>

                                    <div
                                      className={classes.tableCell}
                                      style={{ verticalAlign: "center", padding: 0 }}>
                                      {media.title.rendered}
                                    </div>
                                  </Fade>

                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{ verticalAlign: "center", padding: 0 }}
                                >
                                  <Fade>
                                    <div>
                                      <Chip label={LUTType(media.media_type)} size="small" color={handleChipTypeColor(media.media_type)} />
                                    </div>
                                  </Fade>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="center"
                                  style={{ verticalAlign: "center", width: '5%', padding: 0, margin: 0 }}
                                >
                                  <Fab
                                    style={{ verticalAlign: "center" }}
                                    aria-label="edit"
                                    className={classes.fab}
                                    type="submit"
                                    size="small"
                                    onClick={() => {
                                      router.push(
                                        `/medias/edit/${media.id}`
                                      );
                                    }}
                                  >
                                    <Fade>
                                      <EditSharpIcon className={classes.icon} />
                                    </Fade>
                                  </Fab>
                                </TableCell>
                              </TableRow>
                            </Fragment>
                          );
                        }
                        )
                        }
                      </TableBody>
                    </Table>
                  ) : (
                      <div className={classes.progress} >Δε βρέθηκαν αποτελέσματα.</div>
                    )
                )}

              <Grid item xs={12} className={classes.groupButtons} style={{ justifyContent: 'flex-end' }}>
                <Button
                  id="add-button"
                  type="submit"
                  variant="outlined"
                  className={classes.allAddButton}
                  onClick={() => {
                    router.push("/medias");
                  }}
                >
                  ΕΜΦΑΝΙΣΗ ΟΛΩΝ
              </Button>
                <Button
                  id="add-button"
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.allAddButton}
                  onClick={() => {
                    router.push("/medias/add");
                  }}
                >
                  ΔΗΜΙΟΥΡΓΙΑ
              </Button>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Paper className={classes.paper}>
              <Grid item xs={12} className={classes.groupButtons}>
                <div style={{ display: 'contents' }}>
                  <Typography colSpan={6} className={classes.typography}>
                    Σημαντικές Κατηγορίες
              </Typography>
                </div>
              </Grid>
              {loadingCategories ? (
                <div className={classes.progress} >
                  <CircularProgress />
                </div>
              ) : (
                  categories.length > 0 ? (
                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                      <TableHead id="header">
                        <TableRow>

                          <TableCell id="checkbox" padding="none" className={classes.cell}>
                            Όνομα
                    </TableCell>

                          <TableCell id="checkbox" padding="none" className={classes.cell}>
                            Περιγραφή
                     </TableCell>

                          <TableCell id="checkbox" padding="none" className={classes.cell}>
                            Ενέργεια
                    </TableCell>

                        </TableRow>
                      </TableHead>
                      <TableBody id="table-body" >
                        {categories.map((category) => {
                          return (
                            <Fragment key={category.id}>
                              <TableRow
                                className={classes.row}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={classes.cellWithHeight}
                                >
                                  <Fade>
                                    <div
                                      className={classes.tableCell}
                                      style={{ verticalAlign: "center", padding: 0 }}>
                                      {category.name}
                                    </div>
                                  </Fade>

                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{ verticalAlign: "center", padding: 0 }}
                                >
                                  <Fade>
                                    <div>
                                      {category.description}
                                    </div>
                                  </Fade>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="center"
                                  style={{ verticalAlign: "center", width: '5%', padding: 0, margin: 0 }}
                                >
                                  <Fab
                                    style={{ verticalAlign: "center" }}
                                    aria-label="edit"
                                    className={classes.fab}
                                    type="submit"
                                    size="small"
                                    onClick={() => {
                                      router.push(
                                        `/categories/edit/${category.id}`
                                      );
                                    }}
                                  >
                                    <Fade>
                                      <EditSharpIcon className={classes.icon} />
                                    </Fade>
                                  </Fab>
                                </TableCell>
                              </TableRow>
                            </Fragment>
                          );
                        }
                        )
                        }
                      </TableBody>
                    </Table>
                  ) : (
                      <div className={classes.progress} >Δε βρέθηκαν αποτελέσματα.</div>
                    )
                )}

              <Grid item xs={12} className={classes.groupButtons} style={{ justifyContent: 'flex-end' }}>
                <Button
                  id="add-button"
                  type="submit"
                  variant="outlined"
                  className={classes.allAddButton}
                  onClick={() => {
                    router.push("/categories");
                  }}
                >
                  ΕΜΦΑΝΙΣΗ ΟΛΩΝ
              </Button>
                <Button
                  id="add-button"
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.allAddButton}
                  onClick={() => {
                    router.push("/categories/add");
                  }}
                >
                  ΔΗΜΙΟΥΡΓΙΑ
              </Button>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container >
    </div>
  );
};

export default PageHome;
