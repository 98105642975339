import wp from '../../authentication';

export const filterMedia = async (page, startDate, endDate) => {
    var wpauth =  new wp();

    const validStartDate = (startDate === null) ? false : true;
    const validEndDate = (endDate === null) ? false : true;
    let query;
    if (validStartDate && validEndDate) {
        query =  wpauth.media().before(endDate).after(startDate).page(page).perPage(100).order('desc').orderby('modified');
    } else if (validStartDate) {
        query = wpauth.media().after(startDate).page(page).perPage(100).order('desc').orderby('modified');
    } else if (validEndDate) {
        query = wpauth.media().before(endDate).page(page).perPage(100).order('desc').orderby('modified');
    } else {
        query = wpauth.media().page(page).perPage(100).order('desc').orderby('modified');
    }
    return await query
        .then(function (response) {
            var data = response;
            return data;
        }
        );
};

export default filterMedia;