import wp from '../../authentication';

export const getPages = async (offset, perPage) => {
    var wpauth = new wp();
    let query;
    query = wpauth.pages().offset(offset).perPage(perPage).status(['draft', 'publish']).order('desc').orderby('date');
    return await query
        .then(function (response) {
            var data = response;
            return data;
        }
        );
};

export default getPages;