import wp from '../../authentication';

export const getMedias = async (offset, perPage) => {
    var wpauth = new wp();
    let query;
    query = wpauth.media().offset(offset).perPage(perPage).order('desc').orderby('modified');
    return await query
        .then(function (response) {

            var data = response;
            return data;
            
        }
        );
};

export default getMedias;