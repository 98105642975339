import wp from '../../authentication';

export const filterPage = async (page, startDate, endDate, search, status, perPage) => {
    var wpauth =  new wp();
    
    const validStartDate = (startDate === null) ? false : true;
    const validEndDate = (endDate === null) ? false : true;
    let query;
    if (validStartDate && validEndDate) {
        query =  wpauth.pages().before(endDate).after(startDate).search(search).status(status).page(page).perPage(perPage).order('desc').orderby('modified');
    } else if (validStartDate) {
        query = wpauth.pages().after(startDate).search(search).status(status).page(page).perPage(perPage).order('desc').orderby('modified');
    } else if (validEndDate) {
        query = wpauth.pages().before(endDate).search(search).status(status).page(page).perPage(perPage).order('desc').orderby('modified');
    } else {
        query = wpauth.pages().search(search).status(status).page(page).perPage(perPage).order('desc').orderby('modified');
    }
    return await query
        .then(function (response) {
            var data = response;
            return data;
        }
        );

};

export default filterPage;