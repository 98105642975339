import wp from '../../authentication'

export const getMostUsedCategories = async (page, perPage) => {
    var wpauth = new wp()
    let query
    query = wpauth.categories().page(page).perPage(perPage).order('desc').orderby('count');

    return await query
        .then(function (response) {
            var data = response
            return data;
        }
        ) 

};

export default getMostUsedCategories;